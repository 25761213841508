<template>
  <div class="container mb-5 mt-5" id="contact">
    <div class="row">
      <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
        <div class="contact-card text-start ps-5">
          <h3>
            Program
          </h3>
          <div>
            <h6>
              Duminicā - Joi: 14:00 - 21:00
            </h6>
            <h6>
              Vineri: 14:00 - 21:00
            </h6>
            <h6>
              Sâmbātā 14:00 - 21:00
            </h6>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
        <div class="contact-card">
          <h3>
            Contact
          </h3>
          <div class="row mb-4 mx-5 contact-ul w-50 mx-auto">
            <div class="col-3">
              <a href="https://www.facebook.com/BunkerLaserTagArena/" target="_blank" rel="noopener"
                 class="Bunker facebook page">
                <img :src="require('../assets/svg/facebook.svg')" width="30" height="30"
                     alt="Bunker Arena Facebook Page">
              </a>
            </div>
            <div class="col-3">
              <a href="https://www.instagram.com/bunkerlasertag/" target="_blank" rel="noopener">
                <img :src="require('../assets/svg/instagram.svg')" width="30" height="30"
                     alt="Bunker Arena Instagram">
              </a>
            </div>
            <div class="col-3">
              <a href="mailto:bunkerarena@gmail.com">
                <img :src="require('../assets/svg/mail.svg')" width="30" height="30"
                     alt="Bunker Arena email">
              </a>
            </div>
            <div class="col-3">
              <a href="tel:+40770811611">
                <img :src="require('../assets/svg/phone.svg')" width="30" height="30"
                     alt="Bunker Arena phone">
              </a>
            </div>
          </div>
          <div>
            Calea Unirii nr. 14, Mercur Center, Etaj 5, Craiova
          </div>
          <div class="text-center mt-2">
            <button
              @click="showMap = !showMap"
              class="btn btn-outline-info">
              Vezi pe hartā
            </button>
          </div>
        </div>
      </div>
    </div>
    <MapModal
      :show-modal-prop="showMap"
      @modalToggled="showMap = !showMap"
    ></MapModal>
  </div>
</template>

<script>
import MapModal from './MapModal.vue';

export default {
  name: 'Footer.js',
  components: {
    MapModal,
  },
  data() {
    return {
      showMap: false,
    };
  },
};
</script>

<style scoped lang="scss">
  .contact-card {
    border-radius: 5px;
    font-size: 1rem;
    color: #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 220px;
    box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 1), 0 0px 0px rgba(0, 0, 0, 1);
  }

  .contact-list {
    -webkit-text-size-adjust: 100%;
    font-size: 14px;
    font-family: Raleway,Lato,sans-serif;
    color: #fff;
    line-height: 1.48;
    list-style: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    margin-bottom: 25px;
  }

  .contact-ul {
    -webkit-text-size-adjust: 100%;
    font-size: 14px;
    font-family: Raleway,Lato,sans-serif;
    color: #fff;
    line-height: 1.48;
    list-style: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;

    div {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #161b1f;
      text-align: center;
      font-size: 18px;
      line-height: 40px;
      padding: 0;
      color: #fff;
      transition: all .3s ease-in-out;
      box-shadow: 0 1px 4px 2px rgba(0, 0, 0, .5), 0 0 2px 1px rgba(0, 0, 0, .24%);

      &:hover {
        background-color: #ffdc00;
        border-radius: 70%;
        color: #000;
        width: 40px;
        height: 40px;
      }
    }
  }
</style>
