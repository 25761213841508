<template>
  <nav class="navbar navbar-expand-lg navbar-dark glow-box">
    <button class="navbar-toggler yellow me-2 ms-auto" type="button"
            data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false"
            @click="topBarExpanded = !topBarExpanded"
            aria-label="Toggle navigation">
      <img :src="require('../assets/svg/bars.svg')" width="25" height="25"
           :class="{'d-none' : topBarExpanded}"
           alt="Show NavBar"/>
      <img :src="require('../assets/svg/close.svg')" width="25" height="25"
           :class="{'d-none' : !topBarExpanded}"
           alt="Hide Sidebar">
    </button>
    <div class="container-fluid">
      <div class="collapse navbar-collapse" id="navbarSupportedContent" style="min-height: 70px">
        <router-link :to="{ name: 'home'}">
          <object v-if="showLogo"
                  class="navbar-brand"
                  id="svg1"
                  alt="Bunker Arena Logo"
                  :data="require('../assets/svg/logo.svg')"
                  width="200"
                  type="image/svg+xml">
            Logo
          </object>
        </router-link>
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li v-for="(path, index) in paths"
              :key="'path' + index"
            class="nav-item">
            <router-link v-if="path.route"
                         :to="{ name: path.route}"
                         class="nav-link"
                         :class="{
                'active' : activePath === path.route
               }">
              {{path.name}}
            </router-link>
            <a v-else
               class="nav-link"
               :class="{
                'active' : activePath === path.route
               }"
               @click="linkClicked(path.name)"
               aria-current="page"
               :href="path.link">{{path.name}}</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import 'bootstrap/js/dist/collapse';

export default {
  name: 'Header',
  props: {
    activePath: {
      type: String,
      default: () => 'home',
    },
    showLogo: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      paths: [
        {
          name: 'HOME',
          route: 'home',
        },
        {
          name: 'REZERVÃRI',
          route: 'rezervari',
        },
        {
          name: 'ALBUM',
          route: 'album',
        },
        {
          name: 'CONTACT',
          link: '#contact',
        },
      ],
      topBarExpanded: false,
    };
  },
  methods: {
    linkClicked(path) {
      if (path.indexOf('CONTACT') >= 0) {
        window.setTimeout(() => {
          document.querySelector('#contact').classList.add('contact');
          window.setTimeout(() => {
            document.querySelector('#contact').classList.remove('contact');
          }, 1500);
        }, 500);
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .navbar-brand {
    pointer-events: none;
  }

  .nav-link {
    font-weight: bold;
    text-align: start;
  }

  .nav-link.active {
    text-shadow:
      0 0 3px rgba(255, 255, 255, 0.4),
      0 0 7px rgba(255, 255, 255, 0.4),
      0 0 14px rgba(255, 255, 255, 0.4),
      0 0 25px rgba(28, 189, 239, 0.7),
      0 0 82px rgba(28, 189, 239, 0.7);
  }

  .nav-trigger {
    display: block;
    top: 50px;
    height: 45px;
    box-shadow: 1px 0 3px rgba(0, 0, 0, .6), 0 0 2px 1px rgba(0, 0, 0, .15);
    border-radius: 0 0 50% 50%;
    background-color: #ffdc00;
    text-align: center;
    color: #fff;
    font-size: 18px;
    transition: all .3s ease-in-out;
  }

  .navbar-toggler:focus {
    box-shadow: 0 0 0 0;
  }

  .glow-box{
    /*background-color: #1f252b;*/
  }

  .glow-box::before{
    content: '';
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: 0;
    z-index: -1;
  }

  .glow-box::after{
    content: '';
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: 0;
    z-index: -1;
    filter: blur(25px);
  }
  //.glow-box::before,
  //.glow-box::after {
 //   box-shadow: 0 0 17px 3px #1cbdef,0 0 4px 2px #1cbdef;
 //   background: linear-gradient(235deg, #1f252b, #1cbdef, #1f252b);
  //  content: '';
  //  position: absolute;
  //  left: 0;
  //  top: 0;
  //  width: 100%;
  //  height: 100%;
  //  -webkit-box-shadow: 0 0 17px 3px #1cbdef,0 0 4px 2px #1cbdef;
  //  box-shadow: 0 0 17px 3px #1cbdef,0 0 4px 2px #1cbdef;
  //  z-index: -1;
  //}

  li {
    &:hover {
      cursor: pointer;
      -webkit-box-shadow: 0 0 10px 3px #1CBDEF,0 0 4px 2px #1CBDEF;
      box-shadow: 0 0 10px 3px #1CBDEF,0 0 4px 2px #1CBDEF;
      @media screen and (max-width: 1024px) {
      }
    }
  }
</style>
