<template>
  <div class="cover pb-4" :style="backgroundImage">
    <Header :active-path="activePath" :show-logo="showLogo"></Header>
    <slot name="content"></slot>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  name: 'Cover',
  components: {
    Header,
  },
  props: {
    image: {
      type: String,
      default: '/img/cover_home.webp',
    },
    activePath: {
      type: String,
      default: () => 'home',
    },
    showLogo: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    backgroundImage() {
      return `${'background-image: linear-gradient(90deg, rgba(31, 37, 43, .90), rgba(31, 37, 43, .90)),'
        + '      url('}${this.image});`;
    },
  },
};
</script>

<style scoped lang="scss">
  .cover {
    background-size: cover;
    background-position: center;
  }
</style>
